import React, { useState } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import './sgpform.css'
import { ButtonA } from './Button.js'
import { TextField, Select, MenuItem, Divider, Grid, Snackbar } from '@material-ui/core';
import { Link } from "react-router-dom";
import emailjs from 'emailjs-com';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
        boxShadow: 'none',
        backgroundColor: 'transparent'
    },
    title: {
        flex: 1,
        fontWeight: 'normal',
        color: 'grey',
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(3)
    },
    text: {
        flex: 1,
        fontWeight: 'normal',
        color: '#333',
        marginBottom: theme.spacing(2)
    },
    close: {
        color: 'white',
        backgroundColor: '#9a0808',
        position: 'fixed',
        top: '20px',
        right: '20px',
        '&:hover': {
            color: 'white',
            backgroundColor: '#9a0808',
        }
    },
    uploadedImg: {
        height: '100px',
        paddingLeft: '20px'
    },
    qrImg: {
        height: '500px',
    },
    formControl: {
    },
    formControlCheckox: {
    }
}));

export default function NP24(props) {
    const classes = useStyles();
    // const Razorpay = require('razorpay');
    const [open, setOpen] = useState(props.openform);
    const [message, setmessage] = useState(false);
    const [formstatus, setformstatus] = useState('error');
    const [logtext, setlogtext] = useState('');
    const [issubmitting, setissubmitting] = useState(false);

    const [name, setname] = useState('');
    const [city, setcity] = useState('');
    const [age, setage] = useState('');
    const [gender, setgender] = useState('');
    const [email, setemail] = useState('');
    const [emailerr, setemailerr] = useState('');
    const [mobile, setmobile] = useState('');
    const [itemchoice, setitemchoice] = useState('');
    const [withwife, setwithwife] = useState('');
    const [pan, setpan] = useState('');
    const [img1, setimg1] = useState('');
    // const [img2, setimg2] = useState('');

    const [price, setprice] = useState(500);
    const [userid, setuserid] = useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const countInPayment = (count) => {
        if (count > 0) return 1;
        else return 0;
    }

    const handleInputChange = event => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        if (name === 'name')
            setname(value);
        if (name === 'city')
            setcity(value);
        if (name === 'gender')
            setgender(value);
        if (name === 'age') {
            let reg = /^\d+$/;
            if (value === '' || reg.test(value) === true)
                setage(value);
        }
        if (name === 'email') {
            setemail(value);
            // eslint-disable-next-line no-useless-escape
            let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if (reg.test(value) === false) {
                setemailerr('Email is Not Correct');
                return;
            }
            else {
                setemailerr('');
            }
        }

        if (name === 'mobile') {
            let reg = /^\d+$/;
            if (value === '' || reg.test(value) === true)
                setmobile(value);
        }
        if (name === 'pan')
            setpan(value);
        if (name === 'itemchoice') {
            setitemchoice(value);
            // if (value === '0')
            //     setprice(0);
            // else if (value === '1')
            //     setprice(500);
        }
        if (name === 'withwife')
            setwithwife(value);
        if (name === 'img1')
            setimg1(value);
        // if (name === 'img2')
        //     setimg2(value);
    };

    const validateForm = () => {
        if (name && city && gender && age && email && mobile && itemchoice && pan && withwife && img1) {
            return true;
        }
        else {
            return false;
        }
    }

    const sendEmail = (mail) => {
        emailjs.send("service_8m18sys", "template_uh4himl", {
            to_email: mail,
        }, "user_sY1m8Ki5Oafoc32NNK9TG");
    }

    const handleFormSubmit = (e) => {
        setmessage(!message)
        const formData = new FormData()
        if (validateForm()) {
            setmessage(true)
            setformstatus('info');
            setlogtext('Submitting. Please wait for atleast a minute.');

            formData.append("name", name);
            formData.append("city", city);
            formData.append("gender", gender);
            formData.append("age", age);
            formData.append("email", email);
            formData.append("mobile", mobile);
            formData.append("itemchoice", itemchoice);
            formData.append("pan", pan);
            formData.append("withwife", withwife);
            formData.append("img1", img1);
            // formData.append("img2", img2);
            if(price == 0)
                formData.append("payment_status", "₹0 (Not Applicable)");
            else
                formData.append("payment_status", "Not Paid");

            initiateSubmission(formData, e)
        }
        else {
            setmessage(true)
            setformstatus('error');
            setlogtext('Please fill all details');
        }
    };

    const initiateSubmission = async (formData, e) => {
        document.body.style.overflow = "scroll";
        setOpen(true);
        setformstatus('info');
        setlogtext('Submitting... Please wait !');
        setissubmitting(true);
        await axios({
            url: 'https://satprerna.org/satprerna_backend/home/insert_into_sarvapitri_amavasya_2024.php',
            method: 'post',
            data: formData,
            responseType: 'json'
        })
            .then((response) => {
                setmessage(true);
                console.log(response);
                setuserid(response.data.id);
                if (price > 0) {
                    setformstatus('info');
                    setlogtext('Please complete payment...');
                    paymentHandler(response.data.id, e)
                }
                else {
                    setmessage(true);
                    setformstatus('success');
                    setlogtext('Submitted Successfully !');
                    setissubmitting(false);
                    // sendEmail(email);

                    setemail('');
                    setname('');
                    setcity('');
                    setgender('');
                    setage('');
                    setmobile('');
                    setitemchoice('');
                    setpan('');
                    setwithwife('');
                    setimg1('');
                    // setimg2('');
                }
            })
            .catch(err => {
                console.log('err', err)
                setmessage(true)
                setformstatus('error')
                setlogtext('Something went wrong !')
                setissubmitting(false);
            })
    }

    const paymentHandler = (id, e) => {
        e.preventDefault();
        const options = {
            key: 'rzp_live_bH8zSPYWzsshms',
            amount: price * 100,
            name: 'Satprerna Trust',

            "handler": async function (response) {
                const paymentId = response.razorpay_payment_id;
                const formData = new FormData();
                formData.append("razorpay_payment_id", paymentId);
                formData.append("payment_status", "Paid ₹"+price);
                formData.append("id", id);
                document.body.style.overflow = "scroll";
                setOpen(true);
                setformstatus('info');
                setlogtext('Submitting...');
                await axios({
                    url: 'https://satprerna.org/satprerna_backend/home/update_sarvapitri_amavasya_2024.php',
                    method: 'post',
                    data: formData,
                    responseType: 'json'
                })
                    .then(() => {
                        setmessage(true);
                        setformstatus('success');
                        setlogtext('Payment Completed Successfully !');
                        setissubmitting(false);
                        sendEmail(email);
                    })
                    .then(() => {
                        setemail('');
                        setname('');
                        setcity('');
                        setgender('');
                        setage('');
                        setmobile('');
                        setitemchoice('');
                        setpan('');
                        setwithwife('');
                        setimg1('');
                        // setimg2('');
                        setprice(500);
                    })
                    .catch(err => {
                        console.log('err', err)
                        setmessage(true)
                        setformstatus('error')
                        setlogtext('Something went wrong')
                        setissubmitting(false);
                    })
            },
            modal: {
                escape: false,
                ondismiss() {
                    document.body.style.overflow = "scroll";
                    setOpen(true);
                    setformstatus('error')
                    setlogtext('Payment Failed. Registration Cancelled !')
                    setissubmitting(false);
                }
            },
            prefill: {
                name: name,
                email: email
            },
            notes: {
                address: city
            },
            theme: {
                color: '#fa6400',
            },
        };
        const rzp1 = new window.Razorpay(options);
        setOpen(false);
        rzp1.open();
    }

    const getBase64 = (file, cb) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
    }

    const handleImageUpload = (event, img, callback) => {
        event.preventDefault();
        const file = event.target.files[0];
        let res = '';
        getBase64(file, (result) => {
            res = result;
            callback(res);
            // uploadFile(file.name, file);
        });
    }

    const uploadFile = (name, img) => {
        const formData = new FormData();
        setmessage(true);
        setformstatus('info');
        setlogtext('Uploading...');
        formData.append("dir", "../images/sarvapitri_amavasya_2024/" + name);
        formData.append("image", img);
        axios.post('https://satprerna.org/satprerna_backend/home/store_image_to_folder.php', formData)
            .then(() => {
                setmessage(true);
                setformstatus('success');
                setlogtext('Uploaded');
            });
    }

    return (
        <div>
            <div className="form-header">
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>
                        </Typography>
                        <Link to="/"><IconButton className={classes.close} edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton></Link>
                    </Toolbar>
                </AppBar>
                <svg className="wavy-divider" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 300" preserveAspectRatio="none" width="100%" height="100">
                    <path d="M 1000 299 l 2 -279 c -155 -36 -310 135 -415 164 c -102.64 28.35 -149 -32 -232 -31 c -80 1 -142 53 -229 80 c -65.54 20.34 -101 15 -126 11.61 v 54.39 z"></path>
                    <path d="M 1000 286 l 2 -252 c -157 -43 -302 144 -405 178 c -101.11 33.38 -159 -47 -242 -46 c -80 1 -145.09 54.07 -229 87 c -65.21 25.59 -104.07 16.72 -126 10.61 v 22.39 z"></path>
                    <path d="M 1000 300 l 1 -230.29 c -217 -12.71 -300.47 129.15 -404 156.29 c -103 27 -174 -30 -257 -29 c -80 1 -130.09 37.07 -214 70 c -61.23 24 -108 15.61 -126 10.61 v 22.39 z"></path>
                </svg>
            </div>
            <div className="form-wrapper">
                <h1 className="t1" >सर्वपितृ अमावस्या 2024</h1>
                <h6 className="t2 my-3" >ऑनलाइन रजिस्ट्रेशन फॉर्म</h6>
                <p>
                    <b>आवश्यक नियमावली :</b><br /><br />
                    1: श्राद्ध के समय भाइयों को धोती व खेस (गमछा) पहनना अनिवार्य है।<br /><br />

                    2: बहनें श्राद्ध के समय साड़ी ही पहनें। बालों को अच्छे से बांधकर रखें। शुद्धता का विशेष ध्यान रखें।<br /><br />

                    3: बहनें जिनके घर परिवार में श्राद्धकर्म करने हेतु कोई भी पुरुष न हो, वे श्राद्धकर्म में बैठ सकती हैं।<br /><br />

                    4: भाई व बहनों को लाल रंग के वस्त्र पहनना वर्जित है। हल्के रंग के (सफेद या पीले) वस्त्र पहनें।<br /><br />

                    5: श्राद्धकर्म में पितरों की सद्गति हेतु श्रीमद्भगवद गीता के सातवें, बारहवें व पंद्रहवें अध्याय का पाठ कर उसका जल साथ लाएं।<br /><br />

                    6: श्राद्धपक्ष में यथा शक्ति रामनाम लिखें व अमावस्या के दिन वो रामनाम साथ लाएं, जिसका पुण्यफल पितरों को अर्पित किया जाएगा।<br /><br />

                    7: श्राद्ध के समय श्राद्धकर्ता दक्षिणा हेतु खुल्ले रुपये साथ लेकर बैठे।<br /><br />

                    8: <b>श्राद्धकर्म हेतु आवश्यक वस्तुएं, जो साथ लानी हैं -</b><br />
                    <ul><li>तांबे का 250ml का लोटा - 1 नंग</li>
                        <li>स्टील का 250ml का लोटा - 1 नंग</li>
                        <li>थाल (तर्पण हेतु) - 1 नंग</li>
                        <li>थाली - 2 नंग</li>
                        <li>कटोरी - 2 नंग</li>
                        <li>चम्मच - 2 नंग</li>
                        <li>नैपकिन (हाथ पोछने हेतु ) - 2 नंग </li>
                        <li>आसन (बैठने हेतु) - 1 </li>
                        <li>पिण्डदान पर अर्पण करने के लिए 1 धोती व 1 साड़ी । (शास्त्र में विधान है, लाना चाहें तो आपकी इच्छानुसार ला सकते हैं। ) </li></ul><br />

                    9: सभी के लिए रेजिस्ट्रेशन शुल्क <b>₹500/-</b> अनिवार्य है तथा उपरोक्त श्राद्धकर्म हेतु आवश्यक वस्तुएं स्वयं ला सकतें है या अतिरिक्त शुल्क दे कर स्टॉल से प्राप्त कर सकते हैं।<br /><br />
                </p>
                {/* <Grid container className="my-5" alignItems="center">
                    <Grid item xs={12} md={12}>
                        <div className="mb-5 mb-lg-0">
                            <img className="aa" src={require("../images/3G/6.jpg")} alt="" width="100%" height="auto" />
                        </div>
                    </Grid>
                </Grid> */}
                <Divider />
                <h1>Registration Closed</h1>
                {/* <div className="my-5">
                    <Typography variant="h6" className={classes.title}>
                        व्यक्तिगत विवरण:
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="name"
                                name="name"
                                variant="outlined"
                                required
                                fullWidth
                                id="name"
                                label="पूरा नाम"
                                value={name}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="age"
                                label="आयु"
                                name="age"
                                autoComplete="age"
                                value={age}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id="demo-simple-select-outlined-label-2">लिंग - पुरुष/स्त्री</InputLabel>
                                <Select
                                    required
                                    id="gender"
                                    label="लिंग - पुरुष/स्त्री"
                                    name="gender"
                                    autoComplete="gender"
                                    value={gender}
                                    onChange={handleInputChange}
                                >
                                    <MenuItem value={'Male'}>पुरुष</MenuItem>
                                    <MenuItem value={'Female'}>स्त्री</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="city"
                                label="शहर"
                                name="city"
                                autoComplete="city"
                                value={city}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="email"
                                name="email"
                                variant="outlined"
                                required
                                fullWidth
                                id="email"
                                label="ईमेल"
                                value={email}
                                onChange={handleInputChange}
                            /><p style={{ color: 'orangered', fontSize: '0.8rem', marginLeft: '8px' }}>{emailerr}</p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="mobile"
                                name="mobile"
                                variant="outlined"
                                required
                                fullWidth
                                id="mobile"
                                label="व्हाट्सअप नंबर"
                                value={mobile}
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>

                    <Typography variant="h6" className={classes.title}>
                        अन्य विवरण
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id="demo-simple-select-outlined-label">श्राद्धकर्म हेतु पत्नी सहित आएंगे / अन्य</InputLabel>
                                <Select
                                    required
                                    id="withwife"
                                    label="श्राद्धकर्म हेतु पत्नी सहित आएंगे / अन्य"
                                    name="withwife"
                                    autoComplete="withwife"
                                    value={withwife}
                                    onChange={handleInputChange}
                                >
                                    <MenuItem style={{ whiteSpace: 'normal', wordWrap: 'break-all' }} value={'पत्नी सहित'}>श्राद्धकर्म हेतु पत्नी सहित आएंगे</MenuItem>
                                    <MenuItem style={{ whiteSpace: 'normal', wordWrap: 'break-all' }} value={'अन्य'}>अन्य</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id="demo-simple-select-outlined-label">श्राद्धकर्म हेतु उपर्युक्त सामाग्री स्वयं लाएंगे /<br/> आश्रम से व्यस्था चाहेंगे</InputLabel>
                                <Select
                                    required
                                    id="itemchoice"
                                    label="श्राद्धकर्म हेतु स्वयं लाएंगे / व्यस्था चाहेंगे"
                                    name="itemchoice"
                                    autoComplete="itemchoice"
                                    value={itemchoice}
                                    onChange={handleInputChange}
                                >
                                    <MenuItem style={{ whiteSpace: 'normal', wordWrap: 'break-all' }} value={'स्वयं लाएंगे'}>श्राद्धकर्म हेतु उपर्युक्त सामाग्री स्वयं लाएंगे</MenuItem>
                                    <MenuItem style={{ whiteSpace: 'normal', wordWrap: 'break-all' }} value={'व्यस्था चाहेंगे'}>आश्रम से व्यस्था चाहेंगे</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="pan"
                                label="पैन नंबर"
                                name="pan"
                                autoComplete="pan"
                                value={pan}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <input type="file" id="contained-button-file-1" accept="image/*" onChange={(e) => handleImageUpload(e, img1, setimg1)} style={{ display: 'none' }} />
                            <label htmlFor="contained-button-file-1">
                                <Button variant="contained" color="primary" component="span" size="large">
                                    आधार कार्ड (photo) अपलोड करें 
                                </Button>
                            </label>
                            <img src={img1} alt="" className={classes.uploadedImg} />
                        </Grid>
                    </Grid>
                </div>
                {price>0 && <Grid container spacing={1}>
                <p style={{ color: 'red' }}>
                    <b>अगले चरण में ₹{price} का भुगतान होने के बाद ही पंजीकरण पूर्ण माना जाएगा :</b><br /><br />
                </p>
                </Grid>}
                <Grid container spacing={1}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} className="mt-4 text-right pmt-form-buttons">
                            <div className="ml-3 pmt-submit-btn"><ButtonA buttonto={!issubmitting ? (price == 0 ? "Submit Details" : "Proceed to Payment") : "Please Wait ..."} onClick={handleFormSubmit} disabled={issubmitting} /></div>
                        </Grid>
                        <Snackbar open={message} autoHideDuration={formstatus !== 'success' ? null : 20000} onClose={() => setmessage(false)} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
                            <Alert onClose={() => setmessage(false)} severity={formstatus}>
                                {logtext}
                            </Alert>
                        </Snackbar>
                    </Grid>
                </Grid> */}
            </div>
        </div>
    );
}
